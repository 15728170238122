<template>
    <div>
        <Lista></Lista>
    </div>

</template>

<script>
import BaseView from '@/template/BaseView.vue';
import Lista from '@/components/Leads/leadsLista.vue';

export default {
  components: {
    BaseView,
    Lista
  },
  // created() {
  //   if (this.$store.getters.user.user.level == 'blogger') {this.$router.push("/dynamicRoute/AccessDenied")};
  // },
}
</script>